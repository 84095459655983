// extracted by mini-css-extract-plugin
export var content = "app-module--content--HtEbX";
export var container = "app-module--container--vrK+Z";
export var textContainer = "app-module--text-container--lACKN";
export var paragraphTitle = "app-module--paragraph-title--7Cyk3";
export var paragraph = "app-module--paragraph--RDg-K";
export var imageContainer = "app-module--image-container--6IdvF";
export var videoControl = "app-module--video-control--+Z6+c";
export var download = "app-module--download--B1egm";
export var smackImg = "app-module--smack-img---AUZD";
export var downloadInfo = "app-module--download-info--+aZ29";
export var appStores = "app-module--app-stores--16q4+";
export var appStore = "app-module--app-store--BuCPH";